import axios from 'axios'
import { fetchUrl } from '../fetchurl'

export const postChangeSubscription = async (body, id, token) => {
  const result = await query(body, id, token)

  const data = result?.data || []

  return data
}

const query = async (body, id, token) => {
  return axios
    .post(fetchUrl + '/admin/change-subscription/' + id, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err))
}
