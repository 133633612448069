import axios from 'axios'
import { fetchUrl } from '../fetchurl'

export const postPasswordReset = async (body, token) => {
  const result = await query(body, token)

  const data = result?.data || []

  return data
}

const query = async (body, token) => {
  return axios
    .post(fetchUrl + '/login/forgot/', body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err))
}
