export const dataActionTypes = {
  // Login
  ADMIN_LOGIN_REQUEST: "ADMIN_LOGIN_REQUEST",
  ADMIN_LOGIN_SUCCES: "ADMIN_LOGIN_SUCCES",
  ADMIN_LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",

  PASSWORD_RESET_REQUEST: "PASSWORD_RESET_REQUEST",
  PASSWORD_RESET_SUCCES: "PASSWORD_RESET_SUCCES",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",

  //products

  FETCH_PRODUCTS_REQUEST: "FETCH_PRODUCTS_REQUEST",
  FETCH_PRODUCTS_SUCCES: "FETCH_PRODUCTS_SUCCES",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",

  // subscriptions
  FETCH_SUBSCRIPTIONS_REQUEST: "FETCH_SUBSCRIPTIONS_REQUEST",
  FETCH_SUBSCRIPTIONS_SUCCES: "FETCH_SUBSCRIPTIONS_SUCCES",
  FETCH_SUBSCRIPTIONS_FAILURE: "FETCH_SUBSCRIPTIONS_FAILURE",

  FETCH_SUBSCRIPTION_DETAILS_REQUEST: "FETCH_SUBSCRIPTION_DETAILS_REQUEST",
  FETCH_SUBSCRIPTION_DETAILS_SUCCES: "FETCH_SUBSCRIPTION_DETAILS_SUCCES",
  FETCH_SUBSCRIPTION_DETAILS_FAILURE: "FETCH_SUBSCRIPTION_DETAILS_FAILURE",

  CHANGE_SUBSCRIPTION_REQUEST: "CHANGE_SUBSCRIPTION_REQUEST",
  CHANGE_SUBSCRIPTION_SUCCES: "CHANGE_SUBSCRIPTION_SUCCES",
  CHANGE_SUBSCRIPTION_FAILURE: "CHANGE_SUBSCRIPTION_FAILURE",

  CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
  CREATE_ORDER_SUCCES: "CREATE_ORDER_SUCCES",
  CREATE_ORDER_FAILURE: "CREATE_ORDER_FAILURE",

  // orders

  FETCH_ORDERS_REQUEST: "FETCH_ORDERS_REQUEST",
  FETCH_ORDERS_SUCCES: "FETCH_ORDERS_SUCCES",
  FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE",

  FETCH_ORDER_DETAILS_REQUEST: "FETCH_ORDER_DETAILS_REQUEST",
  FETCH_ORDER_DETAILS_SUCCES: "FETCH_ORDER_DETAILS_SUCCES",
  FETCH_ORDER_DETAILS_FAILURE: "FETCH_ORDER_DETAILS_FAILURE",

  CHANGE_ORDER_REQUEST: "CHANGE_ORDER_REQUEST",
  CHANGE_ORDER_SUCCES: "CHANGE_ORDER_SUCCES",
  CHANGE_ORDER_FAILURE: "CHANGE_ORDER_FAILURE",

  DELETE_ORDER_REQUEST: "DELETE_ORDER_REQUEST",
  DELETE_ORDER_SUCCES: "DELETE_ORDER_SUCCES",
  DELETE_ORDER_FAILURE: "DELETE_ORDER_FAILURE",

  FETCH_ORDER_INVOICE_REQUEST: "FETCH_ORDER_INVOICE_REQUEST",
  FETCH_ORDER_INVOICE_SUCCES: "FETCH_ORDER_INVOICE_SUCCES",
  FETCH_ORDER_INVOICE_FAILURE: "FETCH_ORDER_INVOICE_FAILURE",

  SET_NEW_ORDER: "SET_NEW_ORDER",

  //users

  FETCH_USERS_REQUEST: "FETCH_USERS_REQUEST",
  FETCH_USERS_SUCCES: "FETCH_USERS_SUCCES",
  FETCH_USERS_FAILURE: "FETCH_USERS_FAILURE",

  FETCH_USER_DETAILS_REQUEST: "FETCH_USER_DETAILS_REQUEST",
  FETCH_USER_DETAILS_SUCCES: "FETCH_USER_DETAILS_SUCCES",
  FETCH_USER_DETAILS_FAILURE: "FETCH_USER_DETAILS_FAILURE",

  CHANGE_USER_REQUEST: "CHANGE_USER_REQUEST",
  CHANGE_USER_SUCCES: "CHANGE_USER_REQUEST",
  CHANGE_USER_FAILURE: "CHANGE_USER_REQUEST",

  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCES: "DELETE_USER_SUCCES",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",

  //Notes

  CREATE_NOTE_REQUEST: "CREATE_NOTE_REQUEST",
  CREATE_NOTE_SUCCES: "CREATE_NOTE_SUCCES",
  CREATE_NOTE_FAILURE: "CREATE_NOTE_FAILURE",

  DELETE_NOTE_REQUEST: "DELETE_NOTE_REQUEST",
  DELETE_NOTE_SUCCES: "DELETE_NOTE_SUCCES",
  DELETE_NOTE_FAILURE: "DELETE_NOTE_FAILURE",
};
