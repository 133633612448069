import { useSelector } from "react-redux";
import React from "react";
import { useEffect } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { useDispatch } from "react-redux";
import { getVariations } from "./redux/data/data.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// toast setting
toast.configure({
  autoClose: 3000,
  draggable: false,
});

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = () => {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.data.token);
  const variations = useSelector((state) => state.data.variations);

  useEffect(() => {
    if (token && !variations) {
      dispatch(getVariations());
    }
  }, [dispatch, token, variations]);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route path="/login" name="Login Page">
            {!token ? <Login /> : <Redirect to="/abonnementen" />}
          </Route>
          {token ? (
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          ) : (
            <Redirect to="/login" />
          )}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
