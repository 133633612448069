import { dataActionTypes } from "./data.types.js";
import { fetchToken } from "../../queries/fetchToken";
import { getAllData } from "../../queries/getAllData";
import { getOrders } from "../../queries/getOrders";
import { getSubscriptions } from "../../queries/getSubscriptions";
import { getUsers } from "../../queries/getUsers";
import { getOrderDetails } from "../../queries/getOrderDetails";
import { postNewNote } from "../../queries/postNewNote";
import { removeNote } from "../../queries/removeNote";
import { getSubscriptionDetails } from "../../queries/getSubscriptionDetails";
import { postChangeSubscription } from "../../queries/postChangeSubscription";
import { postChangeOrder } from "../../queries/postChangeOrder";
import { postChangeUser } from "../../queries/postChangeUser";
import { fetchProducts } from "../../queries/fetchProducts";
import { getUserDetails } from "../../queries/getUserDetails";
import { toast } from "react-toastify";
import { postPasswordReset } from "../../queries/postPasswordReset";
import { postCreateOrder } from "../../queries/postCreateOrder";
import { postDeleteOrder } from "../../queries/postDeleteOrder";
import { getOrderInvoice } from "../../queries/getOrderInvoice";
import { removeUser } from "../../queries/deleteUser";
//Login Admin

export const LoginAdmin = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.ADMIN_LOGIN_REQUEST,
    payload: body,
  });

  const token = await fetchToken(body);

  if (token.length > 10) {
    toast.success("Je bent succesvol ingelogd!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: dataActionTypes.ADMIN_LOGIN_SUCCES,
      payload: token,
    });
  } else {
    dispatch({
      type: dataActionTypes.ADMIN_LOGIN_FAILURE,
    });
  }
};

//PW Reset user

export const PasswordReset = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.PASSWORD_RESET_REQUEST,
    payload: body,
  });

  const result = await postPasswordReset(body);

  if (result) {
    toast.success("Wachtwoord herstel is verstuurd!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    const token = getState().data.token;

    dispatch(fetchUserDetails(token, body.id));

    dispatch({
      type: dataActionTypes.PASSWORD_RESET_SUCCES,
    });
  } else {
    dispatch({
      type: dataActionTypes.PASSWORD_RESET_FAILURE,
    });
  }
};

//Products and variations

export const getVariations = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_PRODUCTS_REQUEST,
    payload: body,
  });

  const products = await fetchProducts(body);

  if (products) {
    dispatch({
      type: dataActionTypes.FETCH_PRODUCTS_SUCCES,
      payload: products,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_PRODUCTS_FAILURE,
    });
  }
};

//Subscriptions

export const fetchSubscriptions = (filter) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_SUBSCRIPTIONS_REQUEST,
  });
  const token = getState().data.token;

  const data = await getSubscriptions(token, filter);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_SUBSCRIPTIONS_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_SUBSCRIPTIONS_FAILURE,
    });
  }
};

export const fetchSubscriptionDetails =
  (token, id) => async (dispatch, getState) => {
    dispatch({
      type: dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_REQUEST,
    });

    const data = await getSubscriptionDetails(token, id);

    if (data) {
      dispatch({
        type: dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCES,
        payload: data,
      });
    } else {
      dispatch({
        type: dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_FAILURE,
      });
    }
  };

export const changeSubscription = (body, id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.CHANGE_SUBSCRIPTION_REQUEST,
  });
  const token = getState().data.token;

  const data = await postChangeSubscription(body, id, token);

  if (data) {
    toast.success("Het abonnement is aangepast!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(fetchSubscriptionDetails(token, id));
    dispatch({
      type: dataActionTypes.CHANGE_SUBSCRIPTION_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.CHANGE_SUBSCRIPTION_FAILURE,
    });
  }
};

//Orders

export const fetchOrders = (filter) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_ORDERS_REQUEST,
  });
  const token = getState().data.token;

  const data = await getOrders(token, filter);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_ORDERS_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_ORDERS_FAILURE,
    });
  }
};

export const fetchOrderDetails = (token, id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_ORDER_DETAILS_REQUEST,
  });

  const data = await getOrderDetails(token, id);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_ORDER_DETAILS_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_ORDER_DETAILS_FAILURE,
    });
  }
};

export const fetchOrderInvoice = (id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_ORDER_INVOICE_REQUEST,
  });

  const token = getState().data.token;

  const data = await getOrderInvoice(token, id);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_ORDER_INVOICE_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_ORDER_INVOICE_FAILURE,
    });
  }
};

export const changeOrder = (body, id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.CHANGE_ORDER_REQUEST,
  });
  const token = getState().data.token;

  const data = await postChangeOrder(body, id, token);

  if (data) {
    toast.success("De order is aangepast!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    dispatch(fetchOrderDetails(token, id));

    dispatch({
      type: dataActionTypes.CHANGE_ORDER_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.CHANGE_ORDER_FAILURE,
    });
  }
};

export const createOrder = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.CREATE_ORDER_REQUEST,
  });
  const token = getState().data.token;

  const data = await postCreateOrder(body, token);

  if (data) {
    toast.success("De order is aangemaakt!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch(fetchOrders());

    dispatch({
      type: dataActionTypes.CREATE_ORDER_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.CREATE_ORDER_FAILURE,
    });
  }
};

export const deleteOrder = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.DELETE_ORDER_REQUEST,
  });
  const token = getState().data.token;

  const data = await postDeleteOrder(body, token);

  if (data) {
    toast.success("De order is geannuleerd!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch(fetchOrders());

    dispatch({
      type: dataActionTypes.DELETE_ORDER_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.DELETE_ORDER_FAILURE,
    });
  }
};

export const setNewOrder = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.SET_NEW_ORDER,
    payload: body,
  });
};

//Users

export const fetchUsers = (filter) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_USERS_REQUEST,
  });
  const token = getState().data.token;

  const data = await getUsers(token, filter);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_USERS_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_USERS_FAILURE,
    });
  }
};

export const fetchUserDetails = (token, id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.FETCH_USER_DETAILS_REQUEST,
  });

  const data = await getUserDetails(token, id);

  if (data) {
    dispatch({
      type: dataActionTypes.FETCH_USER_DETAILS_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.FETCH_USER_DETAILS_FAILURE,
    });
  }
};

export const changeUser = (body, id) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.CHANGE_USER_REQUEST,
  });
  const token = getState().data.token;

  const data = await postChangeUser(body, id, token);

  if (data) {
    toast.success("De user is aangepast!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch(fetchUserDetails(token, id));
    dispatch({
      type: dataActionTypes.CHANGE_USER_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.CHANGE_USER_FAILURE,
    });
  }
};

export const deleteUser = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.DELETE_USER_REQUEST,
  });
  const token = getState().data.token;

  const data = await removeUser(body, token);

  if (data) {
    toast.success("De gebruiker is verwijderd!", {
      position: toast.POSITION.TOP_RIGHT,
    });

    dispatch({
      type: dataActionTypes.DELETE_USER_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.DELETE_USER_FAILURE,
    });
  }
};

//Notes
export const createNote = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.CREATE_NOTE_REQUEST,
  });

  const token = getState().data.token;

  const data = await postNewNote(token, body);

  if (data) {
    toast.success("Je notitie is aangepast!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (body.order) {
      dispatch(fetchOrderDetails(token, body.id));
    } else if (body.subscription) {
      dispatch(fetchSubscriptionDetails(token, body.id));
    } else {
      dispatch(fetchUserDetails(token, body.id));
    }

    dispatch({
      type: dataActionTypes.CREATE_NOTE_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.CREATE_NOTE_FAILURE,
    });
  }
};

export const deleteNote = (body) => async (dispatch, getState) => {
  dispatch({
    type: dataActionTypes.DELETE_NOTE_REQUEST,
  });

  const token = getState().data.token;

  const data = await removeNote(token, body);

  if (data) {
    toast.success("Je notitie is verwijderd!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    if (body.order) {
      dispatch(fetchOrderDetails(token, body.id));
    } else if (body.subscription) {
      dispatch(fetchSubscriptionDetails(token, body.id));
    } else {
      dispatch(fetchUserDetails(token, body.id));
    }

    dispatch({
      type: dataActionTypes.DELETE_NOTE_SUCCES,
      payload: data,
    });
  } else {
    dispatch({
      type: dataActionTypes.DELETE_NOTE_FAILURE,
    });
  }
};
