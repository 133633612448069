import { dataActionTypes } from "./data.types.js";

const INITIAL_STATE = {
  token: null,
  variations: null,
  orders: null,
  orderDetails: null,
  subscriptions: null,
  subscriptionDetails: null,
  users: null,
  isLoading: false,
  login: null,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // Admin Login
    case dataActionTypes.ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        login: action.payload,
        isLoading: true,
      };
    case dataActionTypes.ADMIN_LOGIN_SUCCES:
      return {
        ...state,
        token: action.payload,
        isLoading: false,
      };
    case dataActionTypes.ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        login: action.payload,
        isLoading: true,
      };
    case dataActionTypes.PASSWORD_RESET_SUCCES:
      return {
        ...state,
        isLoading: false,
      };
    case dataActionTypes.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    //products

    case dataActionTypes.FETCH_PRODUCTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_PRODUCTS_SUCCES:
      return {
        ...state,
        isLoading: false,
        variations: action.payload,
      };

    case dataActionTypes.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    //Subscriptions

    case dataActionTypes.FETCH_SUBSCRIPTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_SUBSCRIPTIONS_SUCCES:
      return {
        ...state,
        isLoading: false,
        subscriptions: action.payload,
      };
    case dataActionTypes.FETCH_SUBSCRIPTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_SUCCES:
      return {
        ...state,
        isLoading: false,
        subscriptionDetails: action.payload,
      };
    case dataActionTypes.FETCH_SUBSCRIPTION_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    //Order
    case dataActionTypes.FETCH_ORDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_ORDERS_SUCCES:
      return {
        ...state,
        isLoading: false,
        orders: action.payload,
      };
    case dataActionTypes.FETCH_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.FETCH_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_ORDER_DETAILS_SUCCES:
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
      };
    case dataActionTypes.FETCH_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.FETCH_ORDER_INVOICE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_ORDER_INVOICE_SUCCES:
      return {
        ...state,
        isLoading: false,
        orderDetails: {
          ...state.orderDetails,
          order: { ...state.orderDetails.order, invoice: action.payload },
        },
      };
    case dataActionTypes.FETCH_ORDER_INVOICE_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.SET_NEW_ORDER:
      return {
        ...state,
        newOrder: action.payload,
        isloading: false,
      };

    //users

    case dataActionTypes.FETCH_USERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_USERS_SUCCES:
      return {
        ...state,
        isLoading: false,
        users: action.payload,
      };
    case dataActionTypes.FETCH_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.FETCH_USER_DETAILS_SUCCES:
      return {
        ...state,
        isLoading: false,
        userDetails: action.payload,
      };
    case dataActionTypes.FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    case dataActionTypes.DELETE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case dataActionTypes.DELETE_USER_SUCCES:
      return {
        ...state,
        isLoading: false,
      };
    case dataActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default usersReducer;
