import { combineReducers } from 'redux'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import dataReducer from './data/data.reducer.js'
import createFilter from 'redux-persist-transform-filter'

const saveSubsetFilter = createFilter('data', ['token', 'login'])

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveSubsetFilter],
}

const appReducer = combineReducers({
  data: dataReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'SIGNOUT_REQUEST') {
    storage.removeItem('persist:root')

    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)
