import axios from 'axios'
import { fetchUrl } from '../fetchurl'

export const getSubscriptions = async (token, filter) => {
  const result = await query(token, filter);

  const data = result?.data || [];

  return data;
};

const query = async (token, filter) => {
  return axios
    .get(fetchUrl + `/admin/subscriptions?filter=${filter}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err));
};
