import axios from 'axios'
import { fetchUrl } from '../fetchurl'

export const getSubscriptionDetails = async (token, id) => {
  const result = await query(token, id)

  const data = result?.data || []

  return data
}

const query = async (token, id) => {
  return axios
    .get(fetchUrl + '/admin/subscription-details/' + id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
    })
    .catch((err) => console.log(err))
}
